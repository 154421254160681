<!--  -->
<template>
  <div id="map_container" style="height: 100%; width: 100%"></div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {
    var map = initMap({
      tilt: 60,
      heading: 0,
      center: [106.521831, 29.571811],
      zoom: 14,
      // style: whiteStyle,
      displayOptions: {
        poi: false,
      },
      style: purpleStyle,
      skyColors: [
        // 地面颜色
        "rgba(226, 237, 248, 0)",
        // 天空颜色
        "rgba(186, 211, 252, 1)",
      ],
    });

    var view = new mapvgl.View({
      map: map,
    });

    var data = [
      /*
        {geometry: {"type":"LineString","coordinates":[[116.308528,40.050574],[116.307378,40.052314],[116.306175,40.054274],[116.30515,40.056608],[116.304809,40.058955],[116.304055,40.060611]]}},
        {geometry: {"type":"LineString","coordinates":[[116.304414,40.058458],[116.301845,40.058113],[116.299689,40.057795],[116.298485,40.05745],[116.297245,40.056911],[116.2957,40.05698],[116.294964,40.05676]]}},
        {geometry: {"type":"LineString","coordinates":[[116.305222,40.058099],[116.309678,40.059024],[116.311942,40.059548],[116.312714,40.058043],[116.312948,40.057933],[116.313505,40.056829],[116.315804,40.057698]]}},
        */
    ];

    fetch("/data/car.csv")
      .then((rs) => {
        return rs.text();
      })
      .then((csvstr) => {
        var dataSet = mapv.csv.getDataSet(csvstr);
        var data = dataSet.get().slice(0, 80);

        var carlineLayer = new mapvgl.CarLineLayer({
          url: "/img/gltf/car.gltf",
          autoPlay: true,
          step: 0.2,
          scale: 100,
        });

        view.addLayer(carlineLayer);
        carlineLayer.setData(data);
      });

    map.setDefaultCursor("default");
  },

  methods: {},
};
</script>
<style lang='scss' scoped>
</style>